import { jsPDF } from 'jspdf'
import 'svg2pdf.js'

function leadingZero(number) {
    return (number < 10 && number > 0 ? '0' + number : number)
}

function formatDate(date) {
    return [leadingZero(date.getDate()), leadingZero(date.getMonth() + 1), date.getFullYear()].join('/')
}

function loadImageAsBase64(url, callback) {
	const xhr = new XMLHttpRequest()
	xhr.onload = function() {
		var reader = new FileReader()
		reader.onloadend = function() {
			callback(reader.result)
		}
		reader.readAsDataURL(xhr.response)
	}
	xhr.open('GET', url)
	xhr.responseType = 'blob'
	xhr.send()
}

export async function downloadCertificateForUser(user, finishedDate) {
	if (!user) {
		return
	}

	// Load foreground SVG
	const svgURL = require('../assets/images/certificate_foreground.svg')

	// Load svg file content
	const response = await fetch(svgURL)
	const svgString = await response.text()

	if (!svgString) {
		return
	}

	// Parse svg template to a DOM object
	var parser = new DOMParser();
	const svgDOM = parser.parseFromString(svgString, "image/svg+xml")
	const svgElement = svgDOM.firstChild

	if (!svgElement) {
		return
	}

	// Load background image
	const url = require('../assets/images/certificate_background.jpg')

	loadImageAsBase64(url, async (dataUrl) => {
		const doc = new jsPDF({
			orientation: 'landscape',
			unit: 'pt',
		})

		const pageWidth = doc.internal.pageSize.getWidth()
		const pageHeight = doc.internal.pageSize.getHeight()

		// Add background image
		doc.addImage(dataUrl, 'JPEG', 0, 0, pageWidth, pageHeight)

		// Add static foreground text from SVG file
		await doc.svg(svgElement, {
			x: 82,
			y: 145.5,
			width: 657,
			height: 436,
		})

		// Add user first and last name
		doc.setFont('helvetica', 'normal', 'bold')
		doc.setFontSize(16)
		doc.text(user.first_name + ' ' + (user.name || user.last_name), 264, 329)

		// Add finish date
		doc.setFont('helvetica', 'normal', 'normal')
		doc.setFontSize(13)
		doc.text(formatDate(finishedDate), 124, 510)

		doc.save('Attestation NVE - ' + user.first_name + ' ' + (user.name || user.last_name) + '.pdf')
	})
}
